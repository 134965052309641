import { AxiosResponse } from "axios";
import qs from "qs";

import type { ItemType } from "~/components/massiveUpload/ItemUpload";
import api from "~/utils/api/api";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";
import type { Locale } from "~/utils/interfaces/Locale";
import type {
  MassiveItemData,
  MassiveLabMeasuresData,
  MassiveLabPanelsData,
  MassivePricesUploadData,
} from "~/utils/interfaces/massiveUploads";
import { GetItemsApiServiceProps, RequestError, RequestResponse } from "~/utils/interfaces/request";
import type { Item, ListItemsPayload, SectorItemsPayload, ItemWithLabItem } from "~/utils/interfaces/Services";
import { GetPaginatedRawDataItemsPayload } from "~/utils/interfaces/Services";
import { parseItems } from "~/utils/parser/services";

export async function createMultipleItems(items: MassiveItemData, type: ItemType) {
  try {
    const res = await api.post("v2/items/massive-upload/", {
      items,
      item_type: type,
    });
    return res;
  } catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
      data: {},
    };
  }
}

export async function listItems(): Promise<Item[] | undefined> {
  try {
    const res = await api.get<ListItemsPayload>("v2/items/");
    return res.data.results;
  } catch (error) {
    return undefined;
  }
}

export async function listMedicalServices(country: Locale): Promise<Item[] | undefined> {
  try {
    const res = await api.get<ListItemsPayload>(
      `v2/items/?${qs.stringify({
        country,
        resource_type: "medicalservice",
        ignore_locality: true,
        allow_medical_service_price_zero: true,
      })}`,
    );
    return res.data.results;
  } catch (error) {
    return undefined;
  }
}

/**
 * This function gets all the valid items, meaning that the item must have it's corresponding
 * LabMeasures or LabPanels created and assigned
 * @param search The search value to match the item name. Can be blank and will return all items
 * @returns A paginated object with an array of Items
 */
export async function filterFromValidItems(search: string): Promise<AxiosResponse<any> | undefined> {
  try {
    return api.get(`v2/items/?search=${search}`);
  } catch (error) {
    return undefined;
  }
}

export async function updateMultiplePrices(labItems: MassivePricesUploadData) {
  try {
    const res = await api.post("v2/items/massive-update-prices/", {
      lab_items: labItems,
    });
    return res;
  } catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
      data: {},
    };
  }
}

export async function getSectorItems(sectorId: string): Promise<SectorItemsPayload | undefined> {
  try {
    const res = await api.get<SectorItemsPayload>("v2/items/sector-items/", {
      params: { sector_id: sectorId },
    });
    return res.data;
  } catch (error) {
    return undefined;
  }
}

/**
 * Lab Item endpoints below
 */

export async function createMultipleLabMeasures(labMeasures: MassiveLabMeasuresData) {
  try {
    const res = await api.post("v2/lab-measures/massive-upload/", {
      lab_measures: labMeasures,
    });
    return res;
  } catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
      data: {},
    };
  }
}

export async function createMultipleLabPanels(labPanels: MassiveLabPanelsData) {
  try {
    const res = await api.post("v2/lab-panels/massive-upload/", {
      lab_panels: labPanels,
    });
    return res;
  } catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
      data: {},
    };
  }
}

export async function createLabItem(
  labId: string,
  measureId: string,
  medicalActionId: string,
  resourceType: "lab-measures" | "lab-panels",
  data: Partial<ItemWithLabItem>,
) {
  try {
    const resource = resourceType === "lab-measures" ? "measure" : "panel";
    const res = await api.post(`v2/${resourceType}/`, {
      ...data,
      lab: labId,
      [resource]: measureId,
      medical_action: medicalActionId,
    });
    return res;
  } catch (error) {
    return undefined;
  }
}

export async function updateLabItem(
  labItemId: string,
  resourceType: "lab-measures" | "lab-panels",
  data: Partial<ItemWithLabItem>,
) {
  try {
    const res = await api.patch(`v2/${resourceType}/${labItemId}/`, data);
    return res;
  } catch (error) {
    return undefined;
  }
}

export async function getItems({
  params,
  coordinates,
}: GetItemsApiServiceProps): Promise<RequestResponse<CartItemObjectType[]>> {
  try {
    const config = {
      headers: {
        ...(coordinates?.latitude && {
          "x-address-latitude": coordinates.latitude,
          "x-address-longitude": coordinates.longitude,
        }),
      },
    };

    const res = await api.get<GetPaginatedRawDataItemsPayload>(
      `/v2/items/?${qs.stringify(params, {
        arrayFormat: "repeat",
        addQueryPrefix: false,
        encode: false,
        indices: false,
      })}`,
      config,
    );
    const data = parseItems(res.data.results.filter(({ price }) => price));
    return { data };
  } catch (error) {
    return { error } as RequestError;
  }
}
